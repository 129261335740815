<template>
  <main class="kb-main" id="kb-tuition-support-p08">
    <!-- main-header -->
    <lxp-main-header
        :show-breadcrumb="true"
        :show-title="true"
        :title="`자격증 등록/지원 신청`">
      <!--      :file-down-key="guideFile"-->
      <!--        :show-back="true"-->
    </lxp-main-header><!-- main-content -->
    <div class="main-content min-component">
      <!-- section: 상품판매 필수 자격증 -->

      <!-- section: 자격증 이력 -->
      <article class="content-section section-02 section-spacing">
        <header class="section-header">
          <h4 class="title">신청내역</h4>
        </header>
        <div class="guide-table">
          <div class="list-top">
            <div class="top-column pt-5">
              <p class="title text-muted">총 {{paging.totalCount}}건</p>
            </div>
            <div class="top-column">
              <SortButtonSelect
                  v-model="filters.year"
                  v-model:toggle="toggles.year"
                  title="년도"
                  :options="yearOptions"
                  sequence-key="value"
                  name-key="display"
                  button-class="kb-btn kb-btn-outline"
                  :is-all="true"
                  :is-num="true"
                  @update:toggle="closeToggles(toggles, 'year')"
                  @selected="pagingFunc(1)"
              />
              <div class="kb-form-search">
                <div class="kb-form-search-field">
                  <input v-model="filters.keyword" type="text" class="kb-form-search-input" @keyup.enter="getDeptReqList" placeholder="검색">
                  <button class="kb-form-search-btn" @click="getDeptReqList"><i class="icon-search">검색</i></button>
                </div>
                <button class="kb-btn kb-btn-primary" style="margin-left:50px;" @click="regApply"><strong class="text">교육신청</strong></button>
              </div>
            </div>
          </div>
          <div class="kb-table kb-table-bordered-v2">
            <table>
              <colgroup>
                <col style="width:18%">
                <col style="width:12%">
                <col  style="width:25%">
                <col  style="width:10%">
                <col  style="width:15%">
                <col>
              </colgroup>
              <thead>
              <tr>

                <th><span class="th-title">신청부서</span></th>
                <th><span class="th-title">신청자</span></th>
                <th><span class="th-title">교육명(교육목적)</span></th>
                <th><span class="th-title">등록일시</span></th>
                <th colspan="2"><span class="th-title">상태</span></th>
              </tr>
              </thead>
              <tbody>
              <template v-if="deptReqList.length > 0">
                <tr v-for="(item, index) in deptReqList" :key="index">
                  <td><strong class="th-title">{{item.reqDeptNm}}</strong></td>
                  <td><span class="td-text">{{item.reqNm}}</span></td>
                  <td><span class="td-text">{{item.eduGoal}}</span></td>
                  <td><span class="td-text">{{item.regDt}}</span></td>
                  <td v-if="item.stt ==='74'" >등록중</td>
                  <td v-else-if="item.stt ==='81'" >승인요청중</td>
                  <td v-else-if="item.stt ==='00'" >처리완료</td>
                  <td v-else >-</td>
                  <td v-if ="item.stt ==='74' && session.lrnerId === item.reqId" class="d-flex" style="justify-content: space-around"  >
                      <button class="kb-btn kb-btn-xs kb-btn-light-gray" @click="editApply(item.deptReqSn)">수정</button>
                      <button class="kb-btn kb-btn-xs kb-btn-dark text-white" @click="applyAtrz(item)">승인요청</button>
                  </td>
                  <td class="d-flex" style="justify-content: space-around" v-else-if =" item.stt ==='00' || session.lrnerId !== item.reqId"  >
                    <button  class="kb-btn kb-btn-xs kb-btn-light-gray" @click="editApply(item.deptReqSn)" >보기</button>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="6"><span class="td-text text-muted">검색 결과가 없습니다.</span></td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
          <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
        </div>
      </article>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>
import {useStore} from "vuex";
import {computed, reactive, ref, watch} from "vue";
import {closeToggles} from '@/assets/js/ui.init';
import {
  getItems,
  getPaging,
  lengthCheck,
  // numberComma,
  setParamsByQueryString,
  timestampToDateFormat,
  // ymdStrToDateFormat
} from "@/assets/js/util";

import CommonPaginationFront from "@/components/CommonPaginationFront";
import LxpMainHeader from "@/components/common/LxpMainHeader";
import {useRoute, useRouter} from "vue-router";
import SortButtonSelect from "@/components/common/SortButtonSelect";
import {ACT_GET_DEPT_REQ_TRAIN_APPLY_LIST} from "@/store/modules/dept/dept";
export default {
  name: 'ApplyDeptReqTrain',
  components: {LxpMainHeader,SortButtonSelect, CommonPaginationFront},
  setup(){
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const deptReqList = ref([]);
    const allLicenses = ref([]);
    const session = computed(() => store.state.auth.session);

    const currentDate = new Date();

    const yearOptions = (() => {
      const options = [];
      for(let i = currentDate.getFullYear(); i >= 1978 ; i--){
        options.push({value: i, display: `${i}년`});
      }
      return options;
    })();

    const toggles = reactive({
      year: false,
    });

    const filters = reactive({
      year: '',
      keyword: '',
    });

    const paging = ref({pageNo: 1, pageSize: 5, totalCount: 0});

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      console.log('query : ',query)
      router.push({query: query});
    };

    const clickSearch = () => {
      pagingFunc(1,5);
    };


    const regApply = ()=>{
      router.push({name: 'ApplyDeptReqTrainReg'});
    }
    const editApply =(deptReqSn) =>{
      console.log('수정:'+deptReqSn);
      router.push({name: 'ApplyDeptReqTrainReg', query: {deptReqSn:deptReqSn}});
    }

    const applyAtrz =(deptReqSn) =>{
      console.log('승인요청:'+deptReqSn);
    }





    const getDeptReqList = () => {
      store.dispatch(`dept/${ACT_GET_DEPT_REQ_TRAIN_APPLY_LIST}`, {
        year: filters.year > 0 ? filters.year : '',
        crseNm: filters.keyword,
        reqDeptCd: session.value.deptCd,
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if(lengthCheck(res)){
          deptReqList.value = getItems(res);
          paging.value = getPaging(res);
          console.log(deptReqList)
          deptReqList.value.forEach(x => {
            if(x.regDt){
              x.regDt = timestampToDateFormat(x.regDt, 'yyyy.MM.dd');
            }else if(x.bgngDt){
              x.bgngDt = timestampToDateFormat(x.bgngDt, 'yyyy.MM.dd');
            }else if(x.endDt){
              x.endDt = timestampToDateFormat(x.endDt, 'yyyy.MM.dd');
            }
          })
        }else{
          deptReqList.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      });
    }

    watch(() => route.query, () => {
      setParamsByQueryString(route, 'ApplyDeptReqTrain', paging, filters, true);
      getDeptReqList();
    });

    getDeptReqList();

    return{
      deptReqList,filters,yearOptions,closeToggles,toggles,allLicenses,
      paging,pagingFunc,
      clickSearch,
      getDeptReqList,
      regApply,
      editApply,
      applyAtrz,
      session,
    }
  }
};
</script>